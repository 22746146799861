import type { Post } from '~/interfaces';
import { useCommonStore } from '~/store/commonStore';

export function useDeterminLazyHeight() {
  //malo ruzno (uzasno) al radi posao, proveravao sam sve u inspect, i pomaze solidno
  const determineLazyHeight = (post: Post): number => {
    const commonStore = useCommonStore();
    const { type, prediction, repost, url } = post;

    const heightMapping = {
      mobile: {
        regular: 161,
        betting_shop_review: 257,
        repostPrediction: 265,
        repostRepostPrediction: 406,
        repostBettingShopReview: 398,
        repostDefault: 302,
        url: 300
      },
      desktop: {
        regular: 180,
        betting_shop_review: 308,
        repostPrediction: 252,
        repostRepostPrediction: 400,
        repostBettingShopReview: 456,
        repostDefault: 328,
        url: 400
      }
    };

    const mode = commonStore.isMobile ? 'mobile' : 'desktop';

    if (type === 'regular') {
      return heightMapping[mode].regular;
    }
    if (type === 'betting_shop_review') {
      return heightMapping[mode].betting_shop_review;
    }
    if (type === 'repost') {
      if (prediction) return heightMapping[mode].repostPrediction;
      if (repost?.prediction) return heightMapping[mode].repostRepostPrediction;
      if (repost?.bettingShopReview) return heightMapping[mode].repostBettingShopReview;
      return heightMapping[mode].repostDefault;
    }
    if (url && url.length) {
      return heightMapping[mode].url;
    }

    return 160; // default vrednost za svaki slucaj
  };

  return {
    determineLazyHeight
  };
}
